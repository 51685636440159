$max-width-xs: 328;
$width-xs: 100%;
$grid-gap-xs: 8;
$columns-xs: 12;
$container-padding-xs: 12;
$max-width-sm: 704;
$breakpoint-sm: 768;
$width-sm: 95%;
$grid-gap-sm: 16;
$columns-sm: 12;
$container-padding-sm: 12;
$max-width-md: 944;
$breakpoint-md: 1024;
$width-md: 95%;
$grid-gap-md: 16;
$columns-md: 12;
$container-padding-md: 12;
$max-width-lg: 1224;
$breakpoint-lg: 1280;
$width-lg: 95%;
$grid-gap-lg: 24;
$columns-lg: 12;
$container-padding-lg: 14;
$max-width-xl: 1512;
$breakpoint-xl: 1512;
$width-xl: 90%;
$grid-gap-xl: 24;
$columns-xl: 16;
$container-padding-xl: 16;

/*
@media (min-width: 768px) and (max-width: 991px) {

*/
@mixin xs {
  @media screen and (max-width:#{$breakpoint-sm}px) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: #{$breakpoint-sm}px) and (max-width:#{$breakpoint-md}px) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: #{$breakpoint-md}px) and (max-width:#{$breakpoint-lg}px) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: #{$breakpoint-lg}px) and (max-width:#{$breakpoint-xl}px) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: #{$breakpoint-xl}px) {
    @content;
  }
}



@mixin smr {
  @media screen and (min-width: #{$breakpoint-sm}px) {
    @content;
  }
}

@mixin mdr {
  @media screen and (min-width: #{$breakpoint-md}px) {
    @content;
  }
}

@mixin lgr {
  @media screen and (min-width: #{$breakpoint-lg}px) {
    @content;
  }
}

@mixin xlr {
  @media screen and (min-width: #{$breakpoint-xl}px) {
    @content;
  }
}

.container {
  position: relative;
  width: $width-xs;
  margin: 0 auto;
  max-width: #{$max-width-xl}px;
  padding: 0px #{$container-padding-xs}px;
}

.row {
  display: grid;
  width: 100%;
  grid-template-columns: repeat($columns-xs, 1fr);
  max-width: 100%;
  .direction-ltr {
    direction: ltr;
  }
  .direction-rtl {
    direction: rtl;
  }
}

@include xs {
    .visible-xl {
    display: none;
  }
  .visible-lg {
    display: none;
  }
  .visible-md {
    display: none;
  }
  .visible-sm {
    display: none;
  }
  .visible-xs {
    display: block;
  }
  .hidden-xs {
    display: none !important;
  }
 }

.row {
  .col-xs-12 {
    grid-column: span 12;
  }
  .col-xs-11 {
    grid-column: span 11;
  }
  .col-xs-10 {
    grid-column: span 10;
  }
  .col-xs-9 {
    grid-column: span 9;
  }
  .col-xs-8 {
    grid-column: span 8;
  }
  .col-xs-7 {
    grid-column: span 7;
  }
  .col-xs-6 {
    grid-column: span 6;
  }
  .col-xs-5 {
    grid-column: span 5;
  }
  .col-xs-4 {
    grid-column: span 4;
  }
  .col-xs-3 {
    grid-column: span 3;
  }
  .col-xs-2 {
    grid-column: span 2;
  }
  .col-xs-1 {
    grid-column: span 1;
  }
}

@include sm {
  .visible-xl {
    display: none;
  }
  .visible-lg {
    display: none;
  }
  .visible-md {
    display: none;
  }
  .visible-sm {
    display: block;
  }
  .visible-xs {
    display: none;
  }
  .hidden-sm {
    display: none !important;
  }
}

@include smr {
  .row {
    grid-gap: #{$grid-gap-sm}px;
    .col-sm-12 {
      grid-column: span 12;
    }
    .col-sm-11 {
      grid-column: span 11;
    }
    .col-sm-10 {
      grid-column: span 10;
    }
    .col-sm-9 {
      grid-column: span 9;
    }
    .col-sm-8 {
      grid-column: span 8;
    }
    .col-sm-7 {
      grid-column: span 7;
    }
    .col-sm-6 {
      grid-column: span 6;
    }
    .col-sm-5 {
      grid-column: span 5;
    }
    .col-sm-4 {
      grid-column: span 4;
    }
    .col-sm-3 {
      grid-column: span 3;
    }
    .col-sm-2 {
      grid-column: span 2;
    }
    .col-sm-1 {
      grid-column: span 1;
    }
  }
  .container {
    width: $width-sm;
    padding: 0px #{$container-padding-sm}px;
  }
}

@include md {
  .visible-xl {
    display: none;
  }
  .visible-lg {
    display: none;
  }
  .visible-md {
    display: block;
  }
  .visible-sm {
    display: none;
  }
  .visible-xs {
    display: none;
  }
  .hidden-md {
    display: none !important;
  }
}

@include mdr {
  .row {
    grid-gap: #{$grid-gap-md}px;
    .col-md-12 {
      grid-column: span 12;
    }
    .col-md-11 {
      grid-column: span 11;
    }
    .col-md-10 {
      grid-column: span 10;
    }
    .col-md-9 {
      grid-column: span 9;
    }
    .col-md-8 {
      grid-column: span 8;
    }
    .col-md-7 {
      grid-column: span 7;
    }
    .col-md-6 {
      grid-column: span 6;
    }
    .col-md-5 {
      grid-column: span 5;
    }
    .col-md-4 {
      grid-column: span 4;
    }
    .col-md-3 {
      grid-column: span 3;
    }
    .col-md-2 {
      grid-column: span 2;
    }
    .col-md-1 {
      grid-column: span 1;
    }
  }
  .container {
    width: $width-md;
    padding: 0px #{$container-padding-md}px;
  }
}

@include lg {
  .visible-xl {
    display: none;
  }
  .visible-lg {
    display: block;
  }
  .visible-md {
    display: none;
  }
  .visible-sm {
    display: none;
  }
  .visible-xs {
    display: none;
  }
  .hidden-lg {
    display: none !important;
  }
}

@include lgr {
  .row {
    grid-gap: #{$grid-gap-lg}px;
    .col-lg-12 {
      grid-column: span 12;
    }
    .col-lg-11 {
      grid-column: span 11;
    }
    .col-lg-10 {
      grid-column: span 10;
    }
    .col-lg-9 {
      grid-column: span 9;
    }
    .col-lg-8 {
      grid-column: span 8;
    }
    .col-lg-7 {
      grid-column: span 7;
    }
    .col-lg-6 {
      grid-column: span 6;
    }
    .col-lg-5 {
      grid-column: span 5;
    }
    .col-lg-4 {
      grid-column: span 4;
    }
    .col-lg-3 {
      grid-column: span 3;
    }
    .col-lg-2 {
      grid-column: span 2;
    }
    .col-lg-1 {
      grid-column: span 1;
    }
  }
  .container {
    width: $width-lg;
    padding: 0px #{$container-padding-lg}px;
  }
}

@include xl {
  .visible-xl {
    display: block;
  }
  .visible-lg {
    display: none;
  }
  .visible-md {
    display: none;
  }
  .visible-sm {
    display: none;
  }
  .visible-xs {
    display: none;
  }
  .hidden-xl {
    display: none !important;
  }
}

@include xlr {
  .row {
    grid-gap: #{$grid-gap-xl}px;
    .col-xl-16 {
      grid-column: span 16;
    }
    .col-xl-15 {
      grid-column: span 15;
    }
    .col-xl-14 {
      grid-column: span 14;
    }
    .col-xl-13 {
      grid-column: span 13;
    }
    .col-xl-12 {
      grid-column: span 12;
    }
    .col-xl-11 {
      grid-column: span 11;
    }
    .col-xl-10 {
      grid-column: span 10;
    }
    .col-xl-9 {
      grid-column: span 9;
    }
    .col-xl-8 {
      grid-column: span 8;
    }
    .col-xl-7 {
      grid-column: span 7;
    }
    .col-xl-6 {
      grid-column: span 6;
    }
    .col-xl-5 {
      grid-column: span 5;
    }
    .col-xl-4 {
      grid-column: span 4;
    }
    .col-xl-3 {
      grid-column: span 3;
    }
    .col-xl-2 {
      grid-column: span 2;
    }
    .col-xl-1 {
      grid-column: span 1;
    }
  }
  .container {
    width: $width-xl;
    padding: 0px #{$container-padding-xl}px;
  }
}
